import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const Header = styled.header`
    margin: 0;
    padding: 0;
    

    a.title-link {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        align-items: center;
        padding: 10px;
        position: relative;
        
        text-align: center;
        font-style: italic;
        background-color: #f4e7e5;
        color: #514528;
        border-bottom: #fef8f8 solid 15px;

        .name {
            font-size: 35px;
            letter-spacing: -0.05em;
            
            padding: 5px;
            
        }

        .tagline {
            font-size: 17px;
            letter-spacing: -0.02em;
        }

        .home {
            position: absolute;
            display: flex;
            align-items: center;
            top: 0;
            bottom: 0;
            left: 0px;
            padding: 0 15px;
            font-style: normal;
            font-size: 18px;
            text-transform: lowercase;
            border-right: solid 1px rgba(0,0,0,0.05);

            .arr {
                font-size: 30px;
                padding-bottom: 3px;
            }
        }

        &:hover, &:focus {
            color: #201b0f;
            background-color: #f4deda;

            .home {
                background-color: #f2b6ab;
                border-right-color: transparent;
            }
        }
    }
`

export interface SiteHeaderProps {
    title: string;
    isHome?: boolean;
}

const SiteHeader: React.FunctionComponent<SiteHeaderProps> = ({title, isHome=false}) => (
    <Header>
        <Link to={'/'} className="title-link">
            <span className="name">vgtrnsndwch</span>
            <span className="tagline">A recipe collection of vegetarian sandwiches</span>
            { !isHome && <span className="home"><span className="arr">⇽</span><span>Home</span></span> }
        </Link>
    </Header>)


export default SiteHeader