import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const Footer = styled.footer`
    margin: 2em 0 0;
    padding: 2em 10px;
    text-align: center;
    background-color: #fef8f8;

    a {
        text-decoration: none;
        color: inherit;
    }
`

export interface SiteHeaderProps {
    owner: string
}

const SiteFooter: React.FunctionComponent<SiteHeaderProps> = ({owner}) => (
    <Footer>
        © 2020 {/*new Date().getFullYear()*/} <Link to={'/'}>{owner}</Link>
    </Footer>)


export default SiteFooter