import React from "react"
import SiteHeader from "./SiteHeader"
import SiteFooter from "./SiteFooter"
import styled from "@emotion/styled"
import GlobalCss from "./GlobalCss"

export interface LayoutProps {
  title: string;
  isHome?: boolean;
}

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    flex-grow: 1;
  }
`

const Layout: React.FunctionComponent<LayoutProps> = ({ title, children, isHome=false }) => {
  return (
    <Wrapper>
      <GlobalCss />
      <SiteHeader title={title} isHome={isHome} />
      <main>{children}</main>
      <SiteFooter owner={title} />
    </Wrapper>
  )
}

export default Layout
