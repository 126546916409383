import React from "react"
import { Global } from '@emotion/core'

const GlobalCss = () => (
  <Global styles={`
  @import url('https://fonts.googleapis.com/css?family=Playfair+Display:400|Asar&display=swap');

    body {
      padding: 0;
      margin: 0;
      font-family: Times, "Times New Roman", sans-serif;
      font-feature-settings: "liga" 1, "dlig" 1;
      background-color: #fdfdfd;
      color: #1e1601;
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: "Playfair Display",sans-serif;
      font-weight: 400;
    }
  `} />
)

export default GlobalCss